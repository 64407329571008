import React, { Component } from "react"
import Layout from "../components/Layout"
import ReactDOM from "react-dom"

export default class invviewer extends Component {
  constructor() {
    super()
    this.state = {
      iFrameHeight: "100px",
    }
    this.load = this.handleload.bind(this)
  }
  handleload(item) {
    const ifobj = ReactDOM.findDOMNode(item.target)
    ifobj.contentWindow.document.body.onmousemove = () =>
      this.setState({
        iFrameHeight: ifobj.contentWindow.document.body.scrollHeight + "px",
      })
    this.interval = setInterval(() => {
      try {
        var h = ifobj.contentWindow.document.body.scrollHeight
        this.setState({
          iFrameHeight: h + "px",
        })
      } catch (e) {
        clearInterval(this.interval)
      }
    }, 100)
  }

  render() {
    return (
      <Layout>
        <div>
          <iframe
            className="mb-4"
            title="invviewer"
            // src="http://code.n00bs.lc/invviewer/index.html"
            src="/invviewer/index.html"
            scrolling="no"
            width="100%"
            height={this.state.iFrameHeight}
            onLoad={this.load}
            on
          />
        </div>
      </Layout>
    )
  }
}
